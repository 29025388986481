import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useReceiveAccountsList() {
  // Use toast
  const toast = useToast();

  const refReceiveAccountsListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "編號", sortable: true },
    // { key: "supplier_id", label: "公司編號", sortable: true },
    { key: "bank", label: "銀行", sortable: true },
    { key: "account_number", label: "銀行號碼", sortable: true },
    { key: "remark", label: "備註", sortable: true },
    { key: "is_active", label: "狀態", sortable: true },
    // { key: "sort_order", label: "排序", sortable: true },
    { key: "actions", label: "操作" },
  ];
  const perPage = ref(10);
  const totalRow = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("sort_order");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refReceiveAccountsListTable.value
      ? refReceiveAccountsListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRow.value,
    };
  });

  const refetchData = () => {
    refReceiveAccountsListTable.value.refresh();
  };

  const receiveAccountsList = ref(null);

  watch(
    [currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  const fetchReceiveAccounts = (ctx, callback) => {
    store
      .dispatch("receive-account/fetchReceiveAccounts", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { receive_accounts, total } = response.data;
        callback(receive_accounts);
        totalRow.value = total;
        receiveAccountsList.value = receive_accounts
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "獲取收款列表時出錯",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // const bankOptions = ref([]);

  // const refetchOption = () => {
  //   store
  //     .dispatch("app/fetchOptionList", {
  //       banks: true,
  //     })
  //     .then((response) => {
  //       bankOptions.value = response.data.bank;
  //     });
  // };


  onMounted(() => {
    // refetchOption();
    refetchData();
  });

  return {
    fetchReceiveAccounts,
    tableColumns,
    perPage,
    totalRow,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    dataMeta,
    receiveAccountsList,
    refReceiveAccountsListTable,
    ability,
    refetchData,
    // bankOptions,
  };
}
