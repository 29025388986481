<template>
  <div class="">
    <b-card class="">
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <div class="d-flex mb-1 mb-sm-0 align-items-center">
          <h4 class="font-weight-bolder ml-1 text-nowrap mb-0">
            <font-awesome-icon
              class="mr-50"
              icon="fa-solid fa-money-bill-alt"
            />
            收款
          </h4>
          <div class="d-flex ml-3 align-items-center">
            <label>顯示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>條目</label>
          </div>
        </div>
        <div class="d-flex mobile-w100 align-items-center flex-sm-nowrap flex-wrap">
          <b-form-input
            style="width:300px"
            v-model="searchQuery"
            class="search-form mb-1 mb-sm-0 mr-sm-1"
            placeholder="搜尋收款"
          />
          <!-- <b-button
            variant="primary"
            class="mr-sm-1 mobile-w100 mb-1 mb-sm-0 mr-sm-1 d-flex align-items-center text-nowrap"
            @click="$bvModal.show('modal-filter')"
            ><feather-icon
              class="mr-1"
              icon="filterIcon"
              size="18"
            ></feather-icon
            >篩選</b-button
          > -->
          <b-button
            class="mobile-w100 d-flex align-items-center text-nowrap"
            :to="{ name: 'receive-account-create' }"
          >
            <feather-icon class="mr-1" icon="plusIcon" size="18"></feather-icon
            >新增</b-button
          >
        </div>
      </div>
    </b-card>
    <b-card no-body class="card-statistic">
      <b-card-body class="p-2">
        <b-table
          ref="refReceiveAccountsListTable"
          class="position-relative"
          :items="fetchReceiveAccounts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="未找到匹配的記錄"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(is_active)="data">
            <feather-icon
              v-if="data.item.is_active"
              icon="CheckIcon"
              size="18"
              class="mr-50 text-success"
            />
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              :class="`action-trigger-btn ${
                receiveAccountsList.length <= 3 ? 'hor' : 'ver'
              }`"
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content class="">
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <!-- <b-dropdown-item
                v-if="ability.can('read', 'receive-account') || true"
                :to="{
                  name: 'receive-account-view',
                  params: { id: data.item.id },
                }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">詳細資料</span>
              </b-dropdown-item> -->

              <b-dropdown-item
                v-if="ability.can('update', 'receive-account') || true"
                :to="{
                  name: 'receive-account-edit',
                  params: { id: data.item.id },
                }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">修改資料</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item
                v-if="ability.can('delete', 'receive-account') || true"
                @click="removeItem(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">刪除記錄</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRow"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useReceiveAccountsList from "./useReceiveAccountsList";
import receiveAccountStoreModule from "../receiveAccountStoreModule";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {};
  },
  methods: {
    removeItem(item) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("receive-account/deleteReceiveAccount", { id: item.id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "receive-account";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        receiveAccountStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchReceiveAccounts,
      tableColumns,
      perPage,
      totalRow,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      receiveAccountsList,
      refReceiveAccountsListTable,
      ability,
      refetchData,
      bankOptions,
    } = useReceiveAccountsList();

    return {
      fetchReceiveAccounts,
      tableColumns,
      perPage,
      totalRow,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      receiveAccountsList,
      refReceiveAccountsListTable,
      ability,
      refetchData,
      bankOptions,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

// @media screen and (max-width: 768px) {

// }
// @media screen and (max-width: 575px) {

// }
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
</style>
    